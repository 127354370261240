// InvestorRelationHeroSection
import React from "react";

import IconNews from "../../Assets/Images/Investors/icon-news.png";
import IconCalendar from "../../Assets/Images/Investors/icon-calendar.png";
import IconAgm from "../../Assets/Images/Investors/icon-agm.png";
import IconBoard from "../../Assets/Images/Investors/icon-board.png";
import IconAdvisors from "../../Assets/Images/Investors/icon-advisors.png";
import IconFaq from "../../Assets/Images/Investors/icon-faq.png";
const InvestorRelationHeroSection = (props) => {
    const {
        isMobile,
    } = props;

    const data = [
        {
            id:"news",
            icon:IconNews,
            heading:"News",
            description:"Browse our RNS announcements and latest news",
        },{
            id:"calendar",
            icon:IconCalendar,
            heading:"Financial calendar",
            description:"Stay up to date with our financial calendar",
        },{
            id:"agm",
            icon:IconAgm,
            heading:"AGM",
            description:"Details of our latest Annual General Meeting",
        },
        // {
        //     id:"board",
        //     icon:IconBoard,
        //     heading:"Board",
        //     description:"Meet the Pure Sport Group board",
        // },
        {
            id:"advisors",
            icon:IconAdvisors,
            heading:"Advisors",
            description:"Details of our corporate advisors, auditors, legal counsel and share registrar",
        },{
            id:"faq",
            icon:IconFaq,
            heading:"FAQs",
            description:"Browse our frequently asked questions",
        },
    ]


    const renderCard = (data) =>{
        return(
            <div className={"bg-white p-4 d-flex flex-column align-items-start justify-content-between shadow-investor-heading-card"} style={{height:"252px"}}>
               <div className={" d-flex flex-column gap-2"}>
                   <img src={data?.icon} alt={data?.heading} className={"img-fluid"} height={50} width={50}/>
                   <div className={`gilroy-bold fw-400 font-22 text-blue`}>
                       {data?.heading}
                   </div>
                   <div className={`gilroy-light fw-400 font-14 text-blue`}>{data?.description}</div>
               </div>
                <a href={`/investor-relations#${data?.id}`} className={"text-decoration-none d-flex align-items-center"}>
                    <div className={"gilroy-medium fw-400 font-14 text-blue border-blue rounded-5 px-3 py-2"}>
                        Learn more
                    </div>
                </a>
            </div>
        )
    }

    const renderMobile = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center investors-hero-section-mobile"
                >
                    <div className="investors-hero-section-background-mobile w-100 h-100">
                        <div
                            className="h-100 w-100"
                        >
                            <div className={"h-100 container d-flex align-items-start mt-5 pt-5 justify-content-start"}>
                                <div className={"d-flex flex-column gap-4 mt-5"}>
                                    <div
                                        className={`gilroy-bold fw-400 text-white font-32 text-center`}
                                        style={{lineHeight: "46px"}}
                                    >
                                        Investor Centre
                                    </div>
                                    {/*<div className={"d-flex align-items-center justify-content-center"}>*/}
                                    {/*    <div className={"d-flex flex-column align-items-center justify-content-start border-investor-box px-3 py-4"}>*/}
                                    {/*        <div className={"gilroy-bold fw-400 font-18 text-skyblue"}>PURE.L share price</div>*/}
                                    {/*        <div className={"d-flex flex-row align-items-center gap-3"}>*/}
                                    {/*            <div className={"gilroy-bold fw-400 font-35 text-white"}>£3.67</div>*/}
                                    {/*            <div className={"gilroy-bold fw-400 font-18 text-white"}>+0.13p</div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={"gilroy-light fw-400 font-12 text-white"}>Updated 07/08/24, 09:45</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={"row mt-3 p-0"}>
                                        {
                                            data?.map((data, index) => {
                                                return (
                                                    <div key={index}
                                                         className={"col-12 mb-3"}>
                                                        {
                                                            renderCard(data)
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/*<section*/}
                {/*    className="d-flex justify-content-center align-items-center"*/}
                {/*>*/}
                {/*    <Card className="border-0 rounded-0 position-relative w-100"*/}
                {/*          style={{height: "60vh"}}*/}
                {/*    >*/}

                {/*        <div className={"position-absolute top-0 start-0 w-100 h-100"}*/}
                {/*             style={{height: "591px"}}*/}
                {/*        >*/}
                {/*            <img*/}
                {/*                className={"h-100 w-100"}*/}
                {/*                src={HeroBackgroundMobile}*/}
                {/*                alt="Card background"*/}
                {/*                style={cardStyles}*/}
                {/*            />*/}
                {/*            /!*<div*!/*/}
                {/*            /!*    className={"about-us-hero-section-bg position-absolute bottom-0 start-0 h-100 w-100"}></div>*!/*/}
                {/*        </div>*/}
                {/*        <Card.Body*/}
                {/*            className="d-flex align-items-center justify-content-center position-relative"*/}
                {/*        >*/}
                {/*            <div*/}
                {/*                className={`d-flex align-items-center justify-content-evenly flex-column text-center ${isMobile ? "" : "h-100 w-50"}`}>*/}
                {/*                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy fw-400 text-white ${isMobile ? "font-40" : "font-58"}`}*/}
                {/*                        style={{lineHeight: isMobile ? "45px" : "80px"}}*/}
                {/*                    >*/}
                {/*                        Latest updates for investors*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy-light fw-400 text-white opacity-100 ${isMobile ? "font-14" : "font-30"}`}>*/}
                {/*                        Here’s the latest news updates in the world of Pure Sport Group*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</section>*/}
            </>
        )
    };
    const renderLaptop = () => {
        return (
            <>
                <section
                    className="d-flex justify-content-center align-items-center investor-hero-section"
                >
                    <div className="investor-hero-section-background w-100 h-100 position-relative">
                        <div
                            className="position-absolute bottom-0 start-0 h-100 w-100">
                            <div className={"h-100 container d-flex align-items-center justify-content-center"}>
                                <div className={"d-flex flex-column gap-4"}>
                                    <div
                                        className={`gilroy-bold fw-400 text-white font-50 text-center`}
                                        style={{lineHeight: "46px"}}
                                    >
                                        Investor Centre
                                    </div>
                                    {/*<div className={"d-flex align-items-center justify-content-center"}>*/}
                                    {/*    <div className={"d-flex flex-column align-items-center justify-content-start border-investor-box px-3 py-4"}>*/}
                                    {/*        <div className={"gilroy-bold fw-400 font-18 text-skyblue"}>PURE.L share price</div>*/}
                                    {/*        <div className={"d-flex flex-row align-items-center gap-3"}>*/}
                                    {/*            <div className={"gilroy-bold fw-400 font-35 text-white"}>£3.67</div>*/}
                                    {/*            <div className={"gilroy-bold fw-400 font-18 text-white"}>+0.13p</div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className={"gilroy-light fw-400 font-12 text-white"}>Updated 07/08/24, 09:45</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={"row mt-5 p-0 d-flex align-items-center justify-content-center"}>
                                        {
                                            data?.map((data, index) => {
                                                return (
                                                    <div key={index}
                                                         className={"col-4 mb-4"}>
                                                        {
                                                            renderCard(data)
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/*<section*/}
                {/*    className="d-flex justify-content-center align-items-center investor-hero-section"*/}

                {/*>*/}
                {/*    <Card className="border-0 rounded-0 position-relative w-100"*/}
                {/*          style={{height: isMobile ? "60vh" : "591px"}}*/}
                {/*    >*/}

                {/*        <div className={"position-absolute top-0 start-0 w-100 h-100 object-fit-fill"}*/}
                {/*             style={{height: "591px"}}*/}
                {/*        >*/}
                {/*            <img*/}
                {/*                className={"h-100 w-100"}*/}
                {/*                src={HeroBackground}*/}
                {/*                alt="Card background"*/}
                {/*                style={cardStyles}*/}
                {/*            />*/}
                {/*            /!*<div*!/*/}
                {/*            /!*    className={"position-absolute bottom-0 start-0 h-100 w-100"}></div>*!/*/}
                {/*        </div>*/}
                {/*        <Card.Body*/}
                {/*            className="d-flex align-items-center justify-content-center position-relative"*/}
                {/*        >*/}
                {/*            <div*/}
                {/*                className={`d-flex align-items-center justify-content-center flex-column text-center ${isMobile ? "" : "h-100 container"}`}>*/}
                {/*                <div className={"d-flex flex-column align-items-center justify-content-center gap-3"}>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy fw-400 text-white ${isMobile ? "font-40" : "font-58"}`}*/}
                {/*                        style={{lineHeight: isMobile ? "45px" : "80px"}}*/}
                {/*                    >*/}
                {/*                        Latest updates for investors*/}
                {/*                    </div>*/}
                {/*                    <div*/}
                {/*                        className={`gilroy-light fw-400 text-white opacity-100 ${isMobile ? "font-14" : "font-30"}`}>*/}
                {/*                        Here’s the latest news updates in the world of Pure Sport Group*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Card.Body>*/}
                {/*    </Card>*/}
                {/*</section>*/}
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default InvestorRelationHeroSection