// AddressCard
import React from 'react'

const AddressCard = (props) => {
    const {
        index,
        // isMobile,
        background,
        width,
        data,
        height
    } = props;

    return (
        <>
            <div
                key={index}
                className={`d-flex flex-column gap-1 rounded-4 address-card p-3 ${background}`}
                style={{height: height, minWidth: width}}
            >
                <div className={`gilroy-bold fw-400 font-22 text-blue`}>{data?.title}</div>
                <div className={`gilroy-bold fw-400 font-18 text-blue my-2`}>{data?.name}</div>
                <div className={`gilroy-light fw-400 font-18 text-blue d-flex flex-column`}>
                    {data?.address?.line1 && <div>{data?.address?.line1}</div>}
                    {data?.address?.line2 && <div>{data?.address?.line2}</div>}
                    {data?.address?.line3 && <div>{data?.address?.line3}</div>}
                    {data?.address?.line4 && <div>{data?.address?.line4}</div>}
                    {data?.address?.line5 && <div>{data?.address?.line5}</div>}
                    {data?.address?.line6 && <div>{data?.address?.line6}</div>}
                    {data?.address?.country && <div>{data?.address?.country}</div>}
                </div>
            </div>
        </>
    )
}

export default AddressCard