// InvestorRelations
import React, {useEffect, useState} from "react";
import InvestorRelationHeroSection from "../../Components/InvestorRelationComponents/InvestorRelationHeroSection";
import {newsData} from "../../Configs/NewsData";
import NewsCard from "../../Components/ReUseAbleComponents/CardsComponents/NewsCard";
import {useLocation} from "react-router-dom";
import FinancialCalendarSection from "../../Components/InvestorRelationComponents/FinancialCalendarSection";
import BoardSection from "../../Components/InvestorRelationComponents/BoardSection";
import AddressCard from "../../Components/ReUseAbleComponents/CardsComponents/AddressCard";
import FaqSection from "../../Components/InvestorRelationComponents/FaqSection";


const InvestorRelations = (props) => {
    const {
        isMobile,
    } = props;
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('latest');


    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.substring(1);
            // console.log(sectionId)
            const section = document.getElementById(sectionId);
            if (section) {
                // section.scrollIntoView({behavior: "smooth"});
                section.scrollTo(0, 0 );
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);


    const advisors = [
        {
            title: "Brokers",
            name: "Peterhouse Capital Limited",
            address: {
                line1: "3rd Floor",
                line2: "80 Cheapside",
                line3: "London, EC2V 6EE",
                line4: "EC2V 6EE",
                line5: "",
                line6: "",
                country: "United Kingdom"
            }
        },
        {
            title: "Corporate finance",
            name: "Bristol York",
            address: {
                line1: "Linden House",
                line2: "55 The Green",
                line3: "South Bar Street",
                line4: "Banbury",
                line5: "Oxfordshire",
                line6: "OX16 9AB",
                country: "United Kingdom"
            }
        },
        {
            title: "Solicitors",
            name: "JMW",
            address: {
                line1: "Kings House",
                line2: "36-37 King Street",
                line3: "London",
                line4: "EC2V 8BB",
                line5: "",
                line6: "",
                country: "United Kingdom"
            }
        },
        // {
        //     title: "Principal bankers",
        //     name: "TBC",
        //     address: {
        //         line1: "3rd Floor",
        //         line2: "80 Cheapside",
        //         line3: "London",
        //         line4: "EC2V 6EE",
        //         line5: "",
        //         line6: "",
        //         country: "United Kingdom"
        //     }
        // },
        // {
        //     title: "Auditors",
        //     name: "TBC",
        //     address: {
        //         line1: "3rd Floor",
        //         line2: "80 Cheapside",
        //         line3: "London",
        //         line4: "EC2V 6EE",
        //         line5: "",
        //         line6: "",
        //         country: "United Kingdom"
        //     }
        // },
        // {
        //     title: "Solicitors",
        //     name: "JMW",
        //     address: {
        //         line1: "3rd Floor",
        //         line2: "80 Cheapside",
        //         line3: "London",
        //         line4: "EC2V 6EE",
        //         line5: "",
        //         line6: "",
        //         country: "United Kingdom"
        //     }
        // }
    ];
    const renderMobile = () => {
        return (
            <>
                <InvestorRelationHeroSection isMobile={isMobile}/>
                <section className={`bg-white py-4`}
                         id={"news"}
                    // style={{height: "812px"}}
                >
                    {/*<div className={"d-flex flex-column align-items-center justify-content-center mb-3"}>*/}
                    {/*    <div className={"gilroy fw-400 font-28 text-blue"}>News</div>*/}
                    {/*    /!*<div className={"w-75 text-center gilroy-light fw-400 font-14 text-blue opacity my-2"}>Here’s the latest updates in the world of Pure Sport Group</div>*!/*/}
                    {/*</div>*/}

                    <div className={"d-flex flex-column align-items-center justify-content-center mb-3"}>
                        <div className={"gilroy fw-400 font-28 text-blue"}>News</div>
                        {/*<div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>*/}
                        {/*    Here’s the latest updates in the world of Pure Sport Group*/}
                        {/*</div>*/}
                        {/*<div className={"mt-3 d-flex flex-row align-items-center gap-3 mb-3"}>*/}
                        {/*    <div*/}
                        {/*        role="button"*/}
                        {/*        className={`gilroy-bold fw-400 font-16 px-4 py-2 rounded-5 ${activeTab === 'regulatory' ? 'bg-blue text-white border-blue' : 'border-blue text-blue'}`}*/}
                        {/*        onClick={() => setActiveTab('regulatory')}*/}
                        {/*    >*/}
                        {/*        Regulatory*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        role="button"*/}
                        {/*        className={`gilroy-bold fw-400 font-16 px-4 py-2 rounded-5 ${activeTab === 'latest' ? 'bg-blue text-white border-blue' : 'border-blue text-blue'}`}*/}
                        {/*        onClick={() => setActiveTab('latest')}*/}
                        {/*    >*/}
                        {/*        Latest*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {
                        activeTab === 'latest' &&
                        <>
                            <div className={"mb-3 px-3 pb-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>
                                {
                                    newsData?.map((news, index) => {
                                        return (
                                            <NewsCard
                                                key={index}
                                                isMobile={isMobile}
                                                background={"bg-white"}
                                                index={index}
                                                width={"90%"}
                                                imageHeight={213}
                                                image={news?.image}
                                                title={news?.title}
                                                description={news?.description}
                                                newsLink={news?.id}
                                                externalLink={news?.externalLink}
                                                link={news?.link}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    {/*{*/}
                    {/*    activeTab === 'regulatory' &&*/}
                    {/*    <>*/}
                    {/*        <div className={"mb-3 px-3 pb-2 w-100 d-flex gap-3 overflow-x-auto scroll-without-scrollbar"}>*/}
                    {/*            {*/}
                    {/*                newsData?.map((news, index) => {*/}
                    {/*                    return (*/}
                    {/*                        <NewsCard*/}
                    {/*                            key={index}*/}
                    {/*                            isMobile={isMobile}*/}
                    {/*                            background={"bg-white"}*/}
                    {/*                            index={index}*/}
                    {/*                            width={"90%"}*/}
                    {/*                            imageHeight={213}*/}
                    {/*                            image={news?.image}*/}
                    {/*                            title={news?.title}*/}
                    {/*                            description={news?.description}*/}
                    {/*                            newsLink={news?.id}*/}
                    {/*                            externalLink={news?.externalLink}*/}
                    {/*                            link={news?.link}*/}
                    {/*                        />*/}
                    {/*                    )*/}
                    {/*                })*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*}*/}
                    {/*<div className={"w-auto d-flex align-items-center justify-content-center mt-4"}>*/}
                    {/*    {renderLearnMoreButton()}*/}
                    {/*</div>*/}
                </section>

                <section className={`bg-blue`}
                         id={"calendar"}
                         style={{height: "568px"}}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-28 text-white"}>Financial Calendar</div>
                            <div className={"pt-3"}>
                                <FinancialCalendarSection isMobile={isMobile}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}
                         id={"agm"}
                         style={{height: "260px"}}
                >
                    <div className={"container d-flex align-items-center justify-content-center  h-100"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-28 text-blue"}>AGM</div>
                            <div className={"gilroy-medium fw-400 font-16 text-blue opacity my-2 text-center w-100"}>
                                The 2025 Annual General Meeting of Pure Sport Group plc. (the “2025 Annual General Meeting”, or the “Meeting”) will be an in person meeting held at our registered office on Monday, 30 June 2025 at 12 noon.
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className={`bg-blue`}*/}
                {/*         id={"board"}*/}
                {/*>*/}
                {/*    <div className={"container py-5"}>*/}
                {/*        <div className={"d-flex flex-column align-items-center justify-content-center "}>*/}
                {/*            <div className={"gilroy fw-400 font-28 text-white"}>Board</div>*/}
                {/*            <div className={"pt-5 d-flex w-100"}>*/}
                {/*                <BoardSection isMobile={isMobile}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</section>*/}
                <section className={`bg-blue`}
                         id={"advisors"}
                >
                    <div className={"container d-flex align-items-center justify-content-center  h-100  py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center w-100"}>
                            <div className={"gilroy fw-400 font-28 text-white"}>Advisors</div>
                            <div className="d-flex flex-row gap-3 overflow-x-auto scroll-without-scrollbar w-100 py-3 px-2">
                                {advisors.map((data, index) => (
                                    <AddressCard
                                        key={index}
                                        index={index}
                                        isMobile={isMobile}
                                        background={"bg-white"}
                                        width={"281px"}
                                        data={data}
                                        height={"309px"}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}
                         id={"faq"}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-28 text-blue"}>FAQs</div>
                            <div className={"gilroy-light fw-400 font-14 text-blue my-2"}>
                                Browse our frequently asked questions
                            </div>
                        </div>
                        <div className={"mt-3"}>
                            <FaqSection isMpbile={isMobile}/>
                        </div>
                    </div>
                </section>
            </>
        )
    };


    const renderLaptop = () => {
        return (
            <>
                <InvestorRelationHeroSection isMobile={isMobile}/>
                <section className={`bg-white`}
                         id={"news"}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>News</div>
                            {/*<div className={"gilroy-light fw-400 font-18 text-blue opacity my-2"}>*/}
                            {/*    Here’s the latest updates in the world of Pure Sport Group*/}
                            {/*</div>*/}
                            {/*<div className={"mt-3 d-flex flex-row align-items-center gap-3 mb-3"}>*/}
                            {/*    <div*/}
                            {/*        role="button"*/}
                            {/*        className={`gilroy-bold fw-400 font-18 px-4 py-2 rounded-5 ${activeTab === 'regulatory' ? 'bg-blue text-white border-blue' : 'border-blue'}`}*/}
                            {/*        onClick={() => setActiveTab('regulatory')}*/}
                            {/*    >*/}
                            {/*        Regulatory*/}
                            {/*    </div>*/}
                            {/*    <div*/}
                            {/*        role="button"*/}
                            {/*        className={`gilroy-bold fw-400 font-18 px-4 py-2 rounded-5 ${activeTab === 'latest' ? 'bg-blue text-white border-blue' : 'border-blue'}`}*/}
                            {/*        onClick={() => setActiveTab('latest')}*/}
                            {/*    >*/}
                            {/*        Latest*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {activeTab === 'latest' &&
                        <>
                            <div className={"row mt-3 p-0"}>
                                {
                                    newsData?.map((news, index) => {
                                        return (
                                            <div key={index}
                                                 className={"col-4 mb-4"}>
                                                <NewsCard
                                                    key={index}
                                                    isMobile={isMobile}
                                                    background={"bg-white"}
                                                    index={index}
                                                    width={"100%"}
                                                    imageHeight={213}
                                                    image={news?.image}
                                                    title={news?.title}
                                                    description={news?.description}
                                                    newsLink={news?.id}
                                                    externalLink={news?.externalLink}
                                                    link={news?.link}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>}
                        {/*{activeTab === 'regulatory' && <*/}
                        {/*>*/}
                        {/*    <div className={"row mt-3 p-0"}>*/}
                        {/*        {*/}
                        {/*            newsData?.map((news, index) => {*/}
                        {/*                return (*/}
                        {/*                    <div key={index}*/}
                        {/*                         className={"col-4 mb-4"}>*/}
                        {/*                        <NewsCard*/}
                        {/*                            key={index}*/}
                        {/*                            isMobile={isMobile}*/}
                        {/*                            background={"bg-white"}*/}
                        {/*                            index={index}*/}
                        {/*                            width={"100%"}*/}
                        {/*                            imageHeight={213}*/}
                        {/*                            image={news?.image}*/}
                        {/*                            title={news?.title}*/}
                        {/*                            description={news?.description}*/}
                        {/*                            newsLink={news?.id}*/}
                        {/*                            externalLink={news?.externalLink}*/}
                        {/*                            link={news?.link}*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</>}*/}

                    </div>
                </section>
                <section className={`bg-blue m-auto h-100`}
                         id={"calendar"}
                         style={{minHeight: "710px"}}
                >
                    <div className={"container d-flex flex-column align-items-center justify-content-center py-5"} style={{minHeight: "710px"}}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-white"}>Financial Calendar</div>
                            <div className={"pt-3"}>
                                <FinancialCalendarSection isMobile={isMobile}/>
                            </div>
                        </div>

                    </div>
                </section>
                <section className={`bg-white`}
                         id={"agm"}
                         style={{height: "425px"}}
                >
                    <div className={"container d-flex align-items-center justify-content-center  h-100"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>AGM</div>
                            <div className={"gilroy-medium fw-400 font-20 text-blue opacity my-2 text-center w-75"}>
                                The 2025 Annual General Meeting of Pure Sport Group plc. (the “2025 Annual General Meeting”, or the “Meeting”) will be an in person meeting held at our registered office on Monday, 30 June 2025 at 12 noon.
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className={`bg-blue`}*/}
                {/*         id={"board"}*/}
                {/*>*/}
                {/*    <div className={"container py-5"}>*/}
                {/*        <div className={"d-flex flex-column align-items-center justify-content-center "}>*/}
                {/*            <div className={"gilroy fw-400 font-50 text-white"}>Board</div>*/}
                {/*            <div className={"pt-5"}>*/}
                {/*                <BoardSection isMobile={isMobile}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</section>*/}
                <section className={`bg-blue`}
                         id={"advisors"}
                >
                    <div className={"container d-flex align-items-center justify-content-center  h-100  py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-white"}>Advisors</div>
                            <div className="row pt-5">
                                {advisors.map((data, index) => (
                                    <div key={index} className="col-md-6 col-xxl-4 mb-4">
                                        <AddressCard
                                            index={index}
                                            isMobile={isMobile}
                                            background={"bg-white"}
                                            width={"385px"}
                                            data={data}
                                            height={"309px"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-white`}
                         id={"faq"}
                >
                    <div className={"container py-5"}>
                        <div className={"d-flex flex-column align-items-center justify-content-center "}>
                            <div className={"gilroy fw-400 font-50 text-blue"}>FAQs</div>
                            <div className={"gilroy-light fw-400 font-18 text-blue my-2"}>
                                Browse our frequently asked questions
                            </div>
                        </div>
                        <div className={"mt-3"}>
                            <FaqSection isMpbile={isMobile}/>
                        </div>
                    </div>
                </section>
            </>
        )
    };
    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
};

export default InvestorRelations