// FaqSection
import React, {useState} from 'react';
import {Accordion} from 'react-bootstrap';

const FaqSection = (props) => {
    const {
        isMobile,
    } = props;
    const [activeKey, setActiveKey] = useState("0");


    const faqData = [
        {
            question: "Who should I contact at Pure Sport Group regarding investor relations?",
            answer: (
                <>
                    We’re here to help. Please don’t hesitate to contact us using the email address below.
                    <br/>
                    <br/>
                    James Sandford - VP Investor Relations
                    <br/>
                    Michael Watts - Investor Relations Manager
                    <br/>
                    Louise Brixton - PA to Investor Relations
                    <br/>
                    Email:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none ms-1 text-white"}
                       href="mailto:investor.relations@sage.com">investor.relations@sage.com</a>
                    <br/>
                    <br/>
                    <br/>
                    Vicki Bradin, Company Secretary
                    <br/>
                    Telephone:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none ms-1 text-white"}
                       href="tel:+44 203 810 7200">+44 203 810 7200</a>
                    <br/>
                    Email:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none ms-1 text-white"}
                       href="mailto:company.secretary@sage.com">company.secretary@sage.com</a>

                </>
            )
        },
        {
            question: "Who are the company’s registrars and how can I contact them?",
            answer: (
                <>
                    The company’s registrars are Equiniti. They can be contacted here:
                    <br/>
                    <br/>
                    Aspect House
                    <br/>
                    Spencer Road, Lancing
                    <br/>
                    West Sussex, BN99 6DA
                    <br/>
                    United Kingdom
                    <br/>
                    <br/>
                    Telephone:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none ms-1 text-white"}
                       href="tel:0871 384 2859">0871 384 2859</a>
                    <br/>
                    <br/>
                    From outside the UK:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none ms-1 text-white"}
                       href="tel:+44 121 415 7047">+44 121 415 7047</a>
                    <br/>
                    <br/>
                    {/*From outside the UK: +44 121 415 7047    https://equiniti.com/uk/contact-us/*/}
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white"}
                       href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                    <br/>
                    <br/>
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white"}
                       href="https://equiniti.com/uk/contact-us/">https://equiniti.com/uk/contact-us/</a>
                </>
            )
        },
        {
            question: "How can I check my shareholdings online?",
            answer: (
                <>
                    Please contact our registrars, Equiniti. You can visit their website here:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white ms-1"}
                       href="https://equiniti.com/uk/contact-us/">www.shareview.co.uk</a>
                </>
            )
        },
        {
            question: "I have recently changed some of my details - what should I do?",
            answer: (
                <>
                    You may have recently changed your name, address, email address or other personal information. If this is the case, you should contact the registrars and notify them as soon as possible. You can visit their website here:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white ms-1"}
                       href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                </>
            )
        },
        {
            question: "I want to receive my shareholder communications electronically - how do I arrange this?",
            answer: (
                <>
                    <ul>
                        <li>
                            If you would like to change the way you receive your annual report and general meeting notices, please contact Equiniti at:
                            <a target={"_blank"} rel={"noreferrer"}
                               className={"text-decoration-none text-white ms-1"}
                               href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                        </li>
                        <br/>
                        <li>If you would like to receive Sage news alerts by email, please sign up to our alerts service</li>
                        <li>You can also download PDF versions of our annual reports</li>
                    </ul>
                </>
            )
        },
        {
            question: "Where can I access the latest Pure Sport Group annual report?",
            answer: (
                <>
                    Our annual reports can be accessed in our
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-white mx-1"}
                       href="https://www.sage.com/investors/financial-information/annual-report/">annual report</a>
                    section. If you would prefer to receive a physical copy via post, you can contact the registrar, Equiniti and they will facilitate that for you. You can contact them at:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white ms-1"}
                       href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                </>
            )
        },
        {
            question: "When are dividends paid?",
            answer: (
                <>
                    For all information on future dividend payments, please take a look at our financial calendar. You can find more information on our past dividends on our
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-white mx-1"}
                       href="https://www.sage.com/investors/investor-centre/dividends/">dividends</a>

                    page.
                </>
            )
        },
        {
            question: "How will I receive my dividend?",
            answer: (
                <>
                    We encourage our shareholders to have their dividends paid directly into their bank account. This makes the process more secure and removes the risk of cheques being lost in the post. To set this up, please contact our registrars, Equiniti, at:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white ms-1"}
                       href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                </>
            )
        },
        {
            question: "My share certificate has been lost or stolen - what can I do?",
            answer: (
                <>
                    You should contact the registrars, Equiniti, at:
                    <a target={"_blank"} rel={"noreferrer"}
                       className={"text-decoration-none text-white mx-1"}
                       href="https://www.shareview.co.uk">www.shareview.co.uk</a>
                    as soon as possible to rectify this.
                </>
            )
        }
    ];

    const renderMobile = () => {
        return (
            <>
                <Accordion
                    activeKey={activeKey}
                    onSelect={(e) => setActiveKey(e)}
                >
                    {faqData.map((item, index) => (
                        <Accordion.Item
                            eventKey={String(index)}
                            key={index}
                            className={"mb-3 p-0 m-0 border-0 rounded"}
                            style={{backgroundColor:"#161D3A"}}
                        >
                            <Accordion.Header
                                className={`gilroy-bold fw-400 font-16 border-0 rounded`}
                            >{item.question}
                            </Accordion.Header>
                            <Accordion.Body className={"gilroy-medium fw-400 font-14 text-white"}>{item.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <Accordion
                    activeKey={activeKey}
                    onSelect={(e) => setActiveKey(e)}
                >
                    {faqData.map((item, index) => (
                        <Accordion.Item
                            eventKey={String(index)}
                            key={index}
                            className={"mb-3 p-0 m-0 border-0 rounded"}
                            style={{backgroundColor:"#161D3A"}}
                        >
                            <Accordion.Header
                                className={`gilroy-bold fw-400 font-20 border-0 rounded`}
                                >{item.question}
                            </Accordion.Header>
                            <Accordion.Body className={"gilroy-medium fw-400 font-16 text-white"}>{item.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
}

export default FaqSection