import React, {useEffect, useState} from "react";
import {SCREEN_SIZE} from "../Configs/Constants";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import ScrollToTop from "../Components/ScrollToTop";
import HomePage from "../Pages/HomePage/HomePage";
import NavbarComponent from "../Components/NavbarComponent/NavbarComponent";
import FooterComponent from "../Components/FooterComponent/FooterComponent";
import Product from "../Pages/Product/Product";
import ESports from "../Pages/ESports/ESports";
import Sports from "../Pages/Sports/Sports";
// import AboutUs from "../Pages/AboutUs/AboutUs";
import InvestorRelations from "../Pages/InvestorRelations/InvestorRelations";
import ContactUs from "../Pages/ContactUs/ContactUs";
import TermsOfUse from "../Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import NewsDetailPage from "../Pages/NewsDetailPage/NewsDetailPage";

const AppRoutes = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    const routes = [
        {name: "Product", href: "/product"},
        {name: "Esports", href: "/e-sports"},
        {name: "Sports", href: "/sports"},
        // {name: "About Us", href: "/about-us"},
        {name: "Investor Relations", href: "/investor-relations"},
        {name: "Contact Us", href: "/contact-us"},
    ];
    const handleResize = () => {
        if (window.innerWidth < SCREEN_SIZE.MOBILE) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.screen.width, isMobile]);

    const renderContactUsSection = () => {
        return (
            <>
                <div className={`bg-skyblue-contact-us d-flex align-items-center justify-content-center py-5 contact-us-interested`}>
                    <div className={`d-flex align-items-center gap-3 ${isMobile ? "flex-column" : "flex-row"}`}>
                        <div
                            className={`gilroy-bold fw-400 text-blue ${isMobile ? "font-32 text-center" : "contact-us-interested-text"}`}>Interested
                            in Pure Sport Group?
                        </div>
                        <Link to={"/contact-us"} className={`text-decoration-none bg-blue px-4 py-2 rounded-5 mt-2`}>
                            <div className={`gilroy fw-400 text-white ${isMobile ? "font-14" : "font-14"}`}>Contact Us
                            </div>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
    const renderMain = () => {
        return (
            <>
                <BrowserRouter>
                    <ScrollToTop>
                        <div className={`${isMobile ? "bg-blue" : "bg-transparent"}`}>
                            <NavbarComponent isMobile={isMobile} routes={routes}/>
                        </div>
                        <Routes>
                            <Route exact path="/" element={<HomePage isMobile={isMobile}/>}/>
                            <Route exact path="/product" element={<Product isMobile={isMobile}/>}/>
                            <Route exact path="/e-sports" element={<ESports isMobile={isMobile}/>}/>
                            <Route exact path="/sports" element={<Sports isMobile={isMobile}/>}/>
                            {/*<Route exact path="/about-us" element={<AboutUs isMobile={isMobile}/>}/>*/}
                            <Route exact path="/investor-relations" element={<InvestorRelations isMobile={isMobile}/>}/>
                            <Route exact path="/contact-us" element={<ContactUs isMobile={isMobile}/>}/>
                            <Route exact path="/terms-of-use" element={<TermsOfUse isMobile={isMobile}/>}/>
                            <Route exact path="/privacy-policy" element={<PrivacyPolicy isMobile={isMobile}/>}/>
                            <Route exact path="/news/:id" element={<NewsDetailPage isMobile={isMobile}/>}/>
                            <Route exact path="*" element={<HomePage isMobile={isMobile}/>}/>

                        </Routes>
                        {renderContactUsSection()}
                        <FooterComponent isMobile={isMobile} routes={routes}/>
                    </ScrollToTop>
                </BrowserRouter>
            </>
        );
    };

    return renderMain() || "";
};

export default AppRoutes;
