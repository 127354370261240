// FinancialCalendarSection
import React, {useState} from 'react'

const FinancialCalendarSection = (props) => {
    const {
        isMobile,
    } = props;

    const data = {
        2024: [
            { day: "30",month:"SEP", description: "Quarterly results for the three months ending 30 September 2025" },
            { day: "31",month:"DEC", description: "Half-year results for the six months ending 31 December 2025" },
        ],
        2025: [
            { day: "31",month:"MAR", description: "Quarterly results for the three months ending 31 March 2025" },
            { day: "30",month:"JUN", description: "Full-year results for the twelve months ending 30 June 2025" },
            { day: "30",month:"SEP", description: "Quarterly results for the three months ending 30 September 2025" },
            { day: "31",month:"DEC", description: "Half-year results for the six months ending 31 December 2025" }
        ],
        2026: [
            { day: "31",month:"MAR", description: "Quarterly results for the three months ending 31 March 2026" },
            { day: "30",month:"JUN", description: "Full-year results for the twelve months ending 30 June 2026" },
            { day: "30",month:"SEP", description: "Quarterly results for the three months ending 30 September 2026" },
            { day: "31",month:"DEC", description: "Half-year results for the six months ending 31 December 2026" }
        ]
    };

    const [selectedYear, setSelectedYear] = useState(2025);


    const renderMobile = () => {
        return (
            <>
                <div className="d-flex flex-column gap-5 align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center gap-3">
                        {Object.keys(data).map(year => (
                            <button
                                key={year}
                                className={`border-white gilroy-bold fw-400 font-16 px-4 py-2 rounded-5 ${selectedYear === parseInt(year) ? 'bg-white text-blue' : 'bg-transparent text-white'}`}
                                onClick={() => setSelectedYear(parseInt(year))}
                            >
                                {year}
                            </button>
                        ))}
                    </div>

                    <div className="row">
                        {data[selectedYear].map((item, index) => (
                            <div key={index} className="col-12 d-flex flex-row align-items-center mb-4 gap-3">
                                <div className="bg-white p-3 d-flex flex-column text-center align-items-center justify-content-center" style={{height:"64px", width:"64px"}}>
                                    <span className="gilroy-bold fw-400 font-32 text-blue" style={{lineHeight:"34px"}}>{item.day}</span>
                                    <span className="gilroy-bold fw-400 font-14 text-blue">{item.month}</span>
                                </div>
                                <div className="gilroy-medium fw-400 font-14 text-white">
                                    {item.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    };

    const renderLaptop = () => {
        return (
            <>
                <div className="d-flex flex-column gap-5 align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center gap-3">
                        {Object.keys(data).map(year => (
                            <button
                                key={year}
                                className={`border-white gilroy-bold fw-400 font-18 px-4 py-2 rounded-5 ${selectedYear === parseInt(year) ? 'bg-white text-blue' : 'bg-transparent text-white'}`}
                                onClick={() => setSelectedYear(parseInt(year))}
                            >
                                {year}
                            </button>
                        ))}
                    </div>

                    <div className="row mt-4">
                        {data[selectedYear].map((item, index) => (
                            <div key={index} className="col-6 d-flex flex-row align-items-center mb-4 gap-3">
                                <div className="bg-white p-3 d-flex flex-column text-center align-items-center justify-content-center" style={{height:"90px", width:"90px"}}>
                                    <span className="gilroy-bold fw-400 font-36 text-blue" style={{lineHeight:"38px"}}>{item.day}</span>
                                    <span className="gilroy-bold fw-400 font-16 text-blue">{item.month}</span>
                                </div>
                                <div className="w-75 gilroy-medium fw-400 font-20 text-white">
                                    {item.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    };

    const renderMain = () => {
        return (
            <>
                {
                    isMobile ? renderMobile() : renderLaptop()
                }
            </>
        )
    };
    return renderMain();
}

export default FinancialCalendarSection