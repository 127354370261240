import { useEffect } from "react";
import { withRouter } from "../CustomFunctions/withRouter";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const location = useLocation();
  // console.log("location >>>", location)
  useEffect(() => {
    // window.scrollTo(0, 0);
    if (location.pathname === "/product" || location.pathname.startsWith("/product") || location.pathname === "/investor-relations" || location.pathname.startsWith("/investor-relations")) {
    }else{
      window.scrollTo(0, 0);

    }
  }, [location]);

  return <>{props.children}</>;
};

export default withRouter(ScrollToTop);
